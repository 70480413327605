@media only screen and (min-width: 426px) {
    #footerlinks {
        margin-top: 50px;
        padding-left: 45px;
    }

    #footer .hm-button {
        padding: 10px 0 10px 20px;
        display: block;
        text-transform: uppercase;
    }

    #footer-tiny a {
        display: block;
    }
}

@media only screen and (max-width: 425px) {
    #footerlinks {
        margin-top: 20px;
    }

    #footer .hm-button {
        padding: 5px 0 5px 20px;
        display: block;
        text-transform: uppercase;
    }

    #footer-tiny {
        position: relative;
        margin: 30px 0;
    }

    #footer-tiny div {
        position: relative;
        width: 100%;
        text-align: center;
    }
}

#footer .hm-button:hover {
    color: tint(#242039, 50%);
    border-width: 0 !important;
}

#footer {
    background-color: $gray-light;
    padding-bottom: 25px;
    margin-top: 50px;
}
.homepage #footer {
    margin-top: 0px;
}

#footer-tiny {
    font-size: 80%;
}

#logo-footer {
    margin-left: 15px;
}
.footer-nav-link {
    display: block;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 1px;
    margin-bottom: 25px;
    text-transform: uppercase;
}