// main{
//     overflow-x: hidden !important;
// }

#wrapper {
    position: relative;
}
.center-outer {
    position: relative;
}

.center-inner { /*position:absolute;top:50%;*/
}

.clear {
    clear: both;
}

.float-left {
    float: left !important;
}

.float-right {
    float: right !important;
}

.float-none {
    float: none !important;
}

#comingSoonSticker {
    position: absolute;
    width: 151px;
    height: 152px;
    top: -123px;
}

.f-ultra {
    font-weight: 900 !important;
}

.f-bold {
    font-weight: 700 !important;
}

.f-black {
    font-weight: 800 !important;
}

.f-light {
    font-weight: 300 !important;
}

.t-uppercase {
    text-transform: uppercase;
}

.hm-divider {
    position: relative;
    margin: 5px 0 27px 0;
}

.hm-one-col {
    position: relative;
    width: 913px;
    margin: 0 auto;
}

.border-r {
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: #efefef;
}

.border-l {
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: #efefef;
}

.border-t {
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: #efefef;
}

.border-b {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #efefef;
}

.border-tb {
    border-width: 1px 0 1px 0;
    border-style: solid;
    border-color: #efefef;
}

.border-lr {
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: #efefef;
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: #efefef;
}

#hm-contentpage-body {
    padding-top: 25px;
    padding-bottom: 50px;
}

.navbar {
    margin-bottom: 0 !important;
}

.navbar-brand {
    height: 120px !important;
}

.col-no-padding-left {
    padding-left: 0 !important;
}

.col-no-padding-right {
    padding-right: 0 !important;
}

#footer-hillmanLogo {
    position: relative;
    margin-top: 13px;
    margin-right: 20px;
}

// #nav-wallHangers:hover, #sectionblock-wallhangers:hover {
//     background-color: $color-wallHangers;
// }

// #sectionblock-wallhangers .hm-button-box:hover {
//     background-color: $color-wallHangers;
// }

// #nav-decorativeHooks:hover, #sectionblock-decorativehooks:hover {
//     background-color: $color-decorativeHooks;
// }

// #sectionblock-decorativehooks .hm-button-box:hover {
//     background-color: $color-decorativeHooks;
// }

// #nav-hookRails:hover, #sectionblock-hookrails:hover {
//     background-color: $color-hookRails;
// }

// #sectionblock-hookrails .hm-button-box:hover {
//     background-color: $color-hookRails;
// }

// #nav-keyRails:hover, #sectionblock-keyrails:hover {
//     background-color: $color-keyRails;
// }

// #sectionblock-keyrails .hm-button-box:hover {
//     background-color: $color-keyRails;
// }

// #nav-floatingShelves:hover, #sectionblock-floatingshelves:hover {
//     background-color: $color-floatingShelves;
// }

// #sectionblock-floatingshelves .hm-button-box:hover {
//     background-color: $color-floatingShelves;
// }

// #decorativeHooks-sectionblocks .home-sectionblock:hover {
//     background-color: $color-decorativeHooks;
// }

// #decorativeHooks-sectionblocks .hm-button-box:hover {
//     background-color: $color-decorativeHooks;
// }

#floatingShelves-sectionblocks .home-sectionblock {
    padding-top: 77px;
}

#floatingShelves-sectionblocks .home-sectionblock a:hover {
    color: #fff;
}

#floatingShelves-sectionblocks .home-sectionblock img {
    margin-bottom: 20px;
}

#floatingShelves-sectionblocks .home-sectionblock:hover {
    background-color: $color-floatingShelves;
    color: #fff;
}

#floatingShelves-sectionblocks .hm-button-box:hover {
    background-color: $color-floatingShelves;
}

#hookrails-sectionblocks .home-sectionblock {
    padding-top: 77px;
}

#hookrails-sectionblocks .home-sectionblock a:hover {
    color: #fff;
}

#hookrails-sectionblocks .home-sectionblock img {
    margin-bottom: 20px;
}

#hookrails-sectionblocks .home-sectionblock:hover {
    background-color: $color-hookRails;
    color: #fff;
}

#hookrails-sectionblocks .hm-button-box:hover {
    background-color: $color-hookRails;
}

#keyrails-sectionblocks .home-sectionblock a:hover {
    color: #fff;
}

#keyrails-sectionblocks .home-sectionblock:hover {
    background-color: $color-keyRails;
    color: #fff;
}

#keyrails-sectionblocks .hm-button-box:hover {
    background-color: $color-keyRails;
}

.home-sectionblock:hover a {
    color: #fff;
}

.home-sectionblock:hover .hm-button-box {
    border-color: #fff;
}

#wallhangers-sectionblocks .home-sectionblock:hover {
    background-color: $color-wallHangers;
}

.row.small-gutter {
    margin-left: -5px;
    margin-right: -5px;

    div[class^="col-"] {
        padding-left: 5px;
        padding-right: 5px;
    }
}

@media only screen and ( min-width: 992px ) {
    .md-pull-right {
        float: right;
    }
}

.pad-v {
    padding-top: 15px;
    padding-bottom: 15px;
}
.pad-vv {
    padding-top: 25px;
    padding-bottom: 25px;
}

.row-valign {
    display: block;

    div[class^="col-"] {
        display: block;
    }
}
@media only screen and (min-width: 992px) {


.row-valign {
    display: table;

    div[class^="col-"] {
        display: table-cell;
        vertical-align: middle;
        float: none;
    }
}
}

.hm-modal-outer {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 5;
    left: -100%;
}
.hm-modal-backsplash {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0;
    transition: 0.5s linear opacity;
}
.hm-modal-outer.closing {
    transform: translateX(100%);

    .hm-modal-inner {
        opacity: 0;
    }
}
.hm-modal-outer:focus,
.hm-modal-backsplash:focus {
    outline:0;
 }
.hm-modal-inner {
    position: absolute;
    background-color: white;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -65%);
    border-radius: 5px;
    overflow: auto;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    max-height: 100%;
    max-width: 100%;
    opacity: 0;
    transition: 0.35s ease transform, 0.35s linear opacity;
}
.hm-modal-outer.opening {
    transform: translateX(100%);
    .hm-modal-backsplash {
        opacity: 0.35;
    }
}
.hm-modal-outer.active-modal {
    transform: translateX(100%);
    .hm-modal-inner {
        transform: translate(-50%, -50%);
        opacity: 1;
    }
    .hm-modal-backsplash {
        opacity: 0.35;
    }
}
.hm-modal-body iframe {
    display: block;
    height: 820px;
    width: 800px;
}

.close {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 20px;
    height: 20px;
    opacity: 0.3;
  }
  .close:hover {
    opacity: 1;
  }
  .close:before, .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 20px;
    width: 2px;
    background-color: #333;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }


    .video-container{
    // margin-top: 0 !important;
    // height: auto;
    // padding: 0px;

    .video-player-wrapper {
        // position: relative;
        // width: 100%;
        // height: 0px;
        // padding-bottom: 100%;

        &.activated {

            .play-icon{
                border: 0;
                

                &::after{
                    opacity: 1;
                    transition: .2s ease transform;
                    transform: scale(.85);
                }
            }

            &.playing {
                .video-thumbnail {
                    opacity: 0;
                    pointer-events: none;
                }
                .play-icon {
                    opacity: 0;
                    pointer-events: none;
                }
            }

            &.paused {
                .video-thumbnail,
                .play-icon {
                    opacity: 1;
                    pointer-events: auto;
                }
            }
        }

        .video-thumbnail {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-size: cover;
            background-position: center;
            transition: 0.5s ease opacity;
            opacity: 1;
            z-index: 4;
        }

        .video-player {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }

        .play-icon {
            position: absolute;
            top: calc(50% - 50px);
            left: calc(50% - 50px);
            height: 100px;
            width: 100px;
            border-radius: 50%;
            z-index: 5;
            cursor: pointer;
            opacity: 1;

            &:before {
                position: absolute;
                top: 50%;
                left: 55%;
                transform: translate(-50%, -50%);
                border-top: 20px solid transparent;
                border-left: 35px solid white;
                border-bottom: 20px solid transparent;
                content: '';
            }

            &::after{
                content: "";
                position: absolute;
                top: calc(50% - 50px);
                left: calc(50% - 50px);
                height: 100px;
                width: 100px;
                border: 3px solid white;
                border-radius: 50%;
                transform: scale(1);
                opacity: 1;
            }
        }
    }
}
  
