.category-grid{
    .container-fluid{
        .row{
            &.with-image{
                display: flex;

                .inner-products-wrapper{
                    height: 100%;
                    .container-fluid{
                        height: 100%;
                        padding: 0;
                        .row{
                            height: 100%;
                            .hm-grid-cell{
                                
                            }
                        }
                    }
                }

                .context-image-wrapper{
                    height: 100%;
                    padding: 0;
                    .context-background-image{
                        background-size: cover;
                        background-position: center center;
                        background-repeat: no-repeat;
                        height: 100%;
                    }
                }
            }
            .hm-grid-cell{
                background-color: white;
                .hm-grid-copy-wrapper{
                    position: absolute;
                    top: 5%;
                    bottom: auto ;
                    left: 5% ;
                    transform: none;
                    display: block;
                    max-width: 100%;
                    .hm-button-product{
                        font-size: 1.7em;
                        transition: 0.25s ease color, 0.25s ease border-color;
                        letter-spacing: 0;
                        display: block;
                        border: 0;
                        padding: 0;
                        line-height: .75;
                        text-align: left;
                        padding-top: 20px;
 
                    }
                }

                .product-badge-wrapper{
                    position: absolute;
                    right: 5%;
                    top: 5%;
                    .product-badge-asset{
                        max-width: auto;
                        max-height: 80px;
                    }
                }

                &:hover{
                    background-color: #fbfbfb;
                    .hm-button-product{
                        color: #242039;
                    }
                }
            }
        }
        
    }
}


@media screen and (min-width: 1200px){
    .category-grid{
        .container-fluid{
            .row{
                &.with-image{
                    height: 900px;
                    &.contextImageRight{
                        flex-direction: row;
                    }
                    &.contextImageLeft{
                        flex-direction: row-reverse;
                    }
                    .inner-products-wrapper{
                        
                        .container-fluid{
                            .row{
                                .hm-grid-cell{
                                    height: 50%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px){
    .category-grid{
        .container-fluid{
            .row{
                &.with-image{
                    height: 700px;
                    &.contextImageRight{
                        flex-direction: row;
                    }
                    &.contextImageLeft{
                        flex-direction: row-reverse;
                    }
                    .inner-products-wrapper{
                        
                        .container-fluid{
                            .row{
                                .hm-grid-cell{
                                    height: 50%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 990px){
    .category-grid{
        .container-fluid {
            .row{
                &.with-image{
                    height: 750px;
                    &.contextImageRight{
                        flex-direction: row;
                    }
                    &.contextImageLeft{
                        flex-direction: row-reverse;
                    }
                    .inner-products-wrapper{
                        
                        .container-fluid{
                            .row{
                                .hm-grid-cell{
                                    height: 50%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px){
    .category-grid{
        .container-fluid {
            .row{
                &.with-image{
                    &.contextImageRight{
                        flex-direction: column;
                    }
                    &.contextImageLeft{
                        flex-direction: column-reverse;
                    }

                    .context-image-wrapper{
                        height: 550px;
                        overflow: hidden;

                        .context-background-image{
                            background-size: cover;
                            background-position: center top;
                            background-repeat: no-repeat;
                            height: 100%;
                        }
                    }
                    .inner-products-wrapper{
                        width: 100%;
                        .container-fluid{
                            .row{
                                .hm-grid-cell{
                                    height: 50%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


@media only screen and (min-width: 320px) {
    #main-howto {
        margin-top: 30px;
    }

    #wallhangers-points .center-inner {
        margin-top: 0;
    }

    .product-points {
        padding: 0;
    }

    #homepage-hero {
        position: relative;
        background-size: cover;
        padding: 0!important;
        // height: 600px;
        
        .main-carousel-homepage{
            height: 500px;
            .next, .previous{
                display: none;
            }
            .carousel-cell{
                height: 500px;
                &::before{
                    content: "";
                    position: absolute;
                    background-color: rgba(66, 66, 66, 0.40);
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0;
                }
                &.one{
                    background: url("/images/carousel/Top-Nav-FloatingShelfs.jpg");
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                }
                &.two{
                    background: url("/images/carousel/Top-Nav-FloatingShelfs.jpg");
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                }
                &.three{
                    background: url("/images/carousel/Top-Nav-FloatingShelfs.jpg");
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }
        }
        .flickity-page-dots{
            display: block;
            bottom: 25px;
        }
        .container{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            // max-width: 1400px;
            width: 100%;
            padding: 0 20px;
            // margin: 0 auto;
            #homepage-hero-main{
                padding-top: 0 !important;
                .inner-content-wrapper{
                    .slider-content{
                        .h38{
                            text-align: center;
                            color: white !important;
                            font-size: 70px;
                            .b700{
                                color: white;
                            }
                        }
                    }
                }
            }
        }
    }

    #homepage-hero-main {
        padding-top: 17px;
    }

    #homepage-hero-main .h38 {
        color: #fff !important;
        text-align: center !important;
        // font-size: 2.0em !important;
        width: 100%;
    }

    #homepage-hero-main .hm-button-rounded {
        margin: 20px auto;
        display: inline-block;
    }

    #homepage-hero-main .hm-divider {
        display: none;
    }

    #homepage-hero-main .slider-content {
        text-align: center;
    }
}

@media only screen and (min-width: 375px) {

    #main-howto {
        margin-top: 50px;
    }

    #wallhangers-points .center-inner {
        margin-top: 0;
    }

    .product-points {
        padding: 0;
    }

    #homepage-hero {
        position: relative;
        // background: url("/images/carousel/Top-Nav-FloatingShelfs.jpg") no-repeat 48%;
        background-size: cover;
        min-height: 300px;
    }

    #homepage-hero-main {
        padding-top: 75px;
    }

    #homepage-hero-main .hm-button-rounded {
        margin-top: 20px;
        display: inline-block;
    }
}

@media only screen and (max-width: 425px) {
    .mini-howto {
        margin: 0 auto 20px auto;
        float: none !important;
    }

    .mini-howto-center {
        margin: 0 auto 20px auto !important;
    }
}

@media only screen and (min-width: 425px) {

    #wallhangers-points .center-inner {
        margin-top: 0;
    }

    .product-points {
        padding: 0;
    }

    #homepage-hero {
        position: relative;
        // background: url("/images/carousel/Top-Nav-FloatingShelfs.jpg") no-repeat 48%;
        background-size: cover;
        min-height: 345px;
    }

    #homepage-hero-main {
        padding-top: 75px;
    }

    #homepage-hero-main .hm-button-rounded {
        margin-top: 20px;
        display: inline-block;
    }
}

@media only screen and (min-width: 667px) {

    #wallhangers-points .center-inner {
        margin-top: 84px;
    }

    #homepage-hero {
        position: relative;
        // background: url("/images/carousel/Top-Nav-FloatingShelfs.jpg") no-repeat 36%;
        background-size: cover;
        min-height: 439px;
    }
}

@media only screen and (min-width: 736px) {

    #wallhangers-points .center-inner {
        margin-top: 84px;
    }

    #homepage-hero {
        position: relative;
        // background: url("/images/carousel/Top-Nav-FloatingShelfs.jpg") no-repeat 36%;
        background-size: cover;
        min-height: 439px;
    }
}

@media only screen and (min-width: 768px) {

    #wallhangers-points .center-inner {
        margin-top: 0;
    }

    #slider-floatingShelves-mobile h3 {
        font-size: 1.8em !important;
    }

    #homepage-hero-main {
        padding-top: 100px;
    }

    #homepage-hero-main .h38 {
        color: inherit !important;
    }

    #homepage-hero-main .hm-button-rounded {
        margin-top: 20px;
        display: inline-block;
    }

    #homepage-hero-main .slider-content {
        text-align: left;
    }

    #homepage-hero-main .hm-divider {
        display: inline-block;
    }
}

@media only screen and (max-width: 2560px) and (min-width: 1024) {
    .instructions-content {
        text-align: left;
    }
}

@media only screen and (max-width: 1023px) {
    .instructions-content {
        text-align: center;
    }

    .instructions-graphic img {
        margin: 0 auto;
    }
}

@media only screen and (min-width: 980px) {
    #homepage-hero-main .h38 {
        width: 100%;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {

    #wallhangers-points .center-inner {
        margin-top: 84px;
    }

    #homepage-hero {
        position: relative;
        // background: url("/images/carousel/Top-Nav-FloatingShelfs.jpg") no-repeat 43%;
        background-size: cover;
        min-height: 439px;
    }

    #homepage-hero-main {
        padding-top: 100px;
    }

    #homepage-hero-main .hm-button-rounded {
        margin-top: 20px;
        display: inline-block;
    }
}

@media only screen and (min-width: 1440px) and (max-width: 2560px) {

    #wallhangers-points .center-inner {
        margin-top: 84px;
    }

    #homepage-hero {
        position: relative;
        // background: url("/images/carousel/Top-Nav-FloatingShelfs.jpg") no-repeat 36%;
        background-size: cover;
        min-height: 439px;
    }
}

@media only screen and (min-width: 2560px) {

    #wallhangers-points .center-inner {
        margin-top: 84px;
    }

    #homepage-hero {
        position: relative;
        // background: url("/images/carousel/Top-Nav-FloatingShelfs.jpg") no-repeat 36%;
        background-size: cover;
        min-height: 439px;
    }
}
@media only screen and (max-width: 767px) {
    #homepage-hero {
        padding: 90px 0;

        #homepage-hero-main {
            padding-top: 0px;
        }
    }
}

.category-description {
    position: relative;
    margin-top: 50px;
    margin-bottom: 20px;
}
.category-description {
    #category-description-breadcrumbs {
        margin-top: 0;
    }
}
#category-description-breadcrumbs {
    position: relative;
    display: flex;
    color: $text-gray;
    margin-bottom: 10px;
    margin-top: 25px;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 1px;

    a {
        color: $text-gray;
    }
    a:hover {
        text-decoration: underline;
    }
}

#category-description-category {
    font-size: 1.7em !important;
    line-height: 1.2;
    margin-bottom: 10px;

    p{
        margin-bottom: 0;
    }

    strong, b {
        display: block;
    }
}

#category-description-description {
    position: relative;
    line-height: 25px;
    margin-bottom: 60px;
}

.darkMask {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.15);
}

#decorativeHooks-hero {
    position: relative;
    color: #fff;
    text-align: center;
    background: url("/images/carousel/DecoHooks.png") no-repeat 50% 50%;
    background-size: cover;
    min-height: 439px;
    padding-top: 158px;
}

#decorativeHooks-hero img {
    margin: 0 auto;
}

#decorativeHooks-sectionblocks h3 {
    margin-top: 70px;
    padding-left: 57px;
    text-transform: uppercase;
}

#decorativeHooks-howTo .mini-howto {
    width: 225px !important;
}

#decorativeHooks-howTo #mini-howtos {
    position: relative;
    padding-left: 100px !important;
}


#main-howto-video {
    margin-top: 20px;
}

#mini-howtos {
    margin-top: 30px;
}

.mini-howto {
    position: relative;
    float: left;
    width: 222px;
}

.mini-howto-center {
    margin: 0 58px 0 54px;
}

.product-points {
    list-style: none;
}

.product-points li {
    font-size: 1.2em !important;
    padding: 10px 0;
}

.product-points li:before {
    content: "\00BB \0020";
}

.uses {
    position: relative;

    .img-responsive {
        width: 100%;
    }
}

#category-overlay {
    position: position;
    margin-top: -105px;
}

.floating-shelves #category-overlay {
    position: relative;
    margin-top: -57px !important;
}

#category-overlay img {
    position: relative;
    margin: 0 auto;
}

#wallhangers-instructions {
    padding: 90px 0;
    border-bottom: 1px solid #e1e1e1;
}
.hero {
    position: relative;
    color: #fff;
    text-align: center;
    background-position: center;
    background-size: cover;
    min-height: 439px;
}
.hero.defaultHero {
    padding-top: 110px;
}
.hero.callToActionHero {
    padding: 90px 0;
}

.instructions-wrapper {
    padding: 90px 0;

    .container {

        .table-vertical-align-wrapper {
            display: table;
            margin-left: auto;
            margin-right: auto;

            .instructions-content {
                display: block;
                width: 100%;
            }
            .instruction-graphic {
                display: block;
                padding: 0;
                margin-top: 25px;
                text-align: center;

                img {
                    margin: auto;
                }
            }
        }
    }
}
.product-detail {
    .instructions-wrapper {
        margin-top: 50px;
        border-top: 2px solid #efefef;
        border-bottom: 2px solid #efefef;
    }
}
@media only screen and ( min-width: 768px ) {
    .hero.defaultHero {
        padding-top: 180px;
    }
}

@media only screen and ( min-width: 992px ) {
    .instructions-wrapper {
        padding: 125px 0;

        .container {

            .table-vertical-align-wrapper {

                .instructions-content {
                    display: table-cell;
                    width: 400px;
                    max-width: 100%;
                    vertical-align: middle;
                    margin-right: 50px;
                }
                .instruction-graphic {
                    margin: 0;
                    display: table-cell;
                    vertical-align: middle;
                    padding-left: 50px;
                }
            }
        }
    }
    .flickity-prev-next-button {
        display: none;
    }
}

.how-to-wrapper {
    .secondary-videos {
        margin-top: 25px;
        
        .howto-video-container {
            text-align: left;
        }
    }
}

.cta-hero-content {
    .heading {
        margin-bottom: 10px;
        .heading-copy {
            display: block;
            line-height: 105%;
            font-size: 2.9em;
            margin-bottom: 15px;
        }
    }
    .body {
        margin-bottom: 35px;
    }
    .hero-cta {

    }
    .inline-badge {
        max-width: 70px;
        margin-bottom: 10px;
    }
}
.floating-badge {
    position: absolute;
    top: 25px;
    left: 25px;
    max-width: 125px;
    z-index: 1;
}