.howTo .main-howto {
    position: relative; /*width:778px;*/

    margin: 0 auto;
}

.main-howto {
    h1 {
        font-weight: 400;
    }
}

#main-howto-buynow {
    margin-top: 15px;
}
.howTo #main-howto-video {
    margin-bottom: 30px;
}
.howTo #mini-howtos {
    margin: 60px 0;
}

.howto-howto-description {
    margin-top: 5px;
    margin-bottom: 30px;
}

.howto-video-title {
    padding: 12px 0;
}

.howto-big {
    margin-bottom: 30px;
}

.howto-video-container-video {
    position: relative;
    margin-bottom: 10px;
}

.howto-video-container-title::after {
    display: block;
    content: url("/images/hm-divider-2.png");
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed,
.video-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.btn_playVideo {
    cursor: pointer;
    display: block;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
}
.btn_playVideo:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: black;
    opacity: 0.25;
    height: 100%;
    width: 100%;
    z-index: 0;
    transition: 0.35s ease opacity;
}
// .btn_playVideo:before {
//     content: '';
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     opacity: 1;
//     transition: 0.35s ease opacity;
//     width: 0; 
//     height: 0; 
//     border-top: 20px solid transparent;
//     border-bottom: 20px solid transparent;
//     border-left: 35px solid white;
//     z-index: 2;
// }
.howto-video-container-video:hover .btn_playVideo:after {
    opacity: 0.25;
}
.howto-video-container-video:hover .btn_playVideo:before {
    opacity: 1;
}
.video-thumbnail {
    width: 100%;
}

.how-to-wrapper{
    .container{
        width: 100%;
        padding: 0px;
        .row{
            margin: 0px 0px;
        }
        .col-md-6, .col-md-4{
            padding: 0;
        }
            .main-howto{
                background-color: #efefef;
                .row{
                    height: 100%;
                    position: relative;
                    .video-info{
                        text-align: left;
                        height: 100%;
                        position: relative;
                        .info-inner-wrapper{
                            .howto-title{
                                font-size: 24px;
                            }
                            .seperator{

                            }
                            .howto-video-title{
                                font-size: 24px;
                                font-weight: bold;
                                padding: 0;
                                margin-top: 0;
                            }
                        }
                    }

                .video-container{
                    margin-top: 0 !important;
                    height: auto;
                    padding: 0px;

                    .video-player-wrapper {
                        position: relative;
                        width: 100%;
                        height: 0px;
                        padding-bottom: 100%;

                        &.activated {
                            &.playing {
                                .video-thumbnail {
                                    opacity: 0;
                                    pointer-events: none;
                                }
                                .play-icon {
                                    opacity: 0;
                                    pointer-events: none;
                                }
                            }

                            &.paused {
                                .video-thumbnail,
                                .play-icon {
                                    opacity: 1;
                                    pointer-events: auto;
                                }
                            }
                        }

                        .video-thumbnail {
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 100%;
                            width: 100%;
                            background-size: cover;
                            background-position: center;
                            transition: 0.5s ease opacity;
                            opacity: 1;
                            z-index: 4;
                        }

                        .video-player {
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 100%;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

#howto-videos{
    #mini-howtos{
        .row{
            .category-title{
                padding-left: 15px  !important;
            }

            .btn_playVideo{
                overflow: hidden;
                height: 150px;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;

            }
        }

        .play-icon {
            position: absolute;
            top: calc(50% - 35px);
            left: calc(50% - 35px);
            height: 75px;
            width: 75px;
            border: 3px solid white;
            border-radius: 50%;
            z-index: 5;
            cursor: pointer;
            opacity: 1;

            &:before {
                position: absolute;
                top: 50%;
                left: 55%;
                transform: translate(-50%, -50%);
                border-top: 15px solid transparent;
                border-left: 25px solid white;
                border-bottom: 15px solid transparent;
                content: '';
            }
        }
    }
}


@media screen and (min-width: 1400px){
    .how-to-wrapper{
        .container{
            .main-howto{
                .row{
                    .video-info{
                        padding-top: 21%;
                        .info-inner-wrapper{
                            padding-left: 15%;
                            max-width: 75%;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1100px) and (max-width: 1399px){
    .how-to-wrapper{
        .container{
            .main-howto{
                .row{
                    .video-info{
                        padding-top: 16%;
                        .info-inner-wrapper{
                            padding-left: 15%;
                            max-width: 75%;
                            .howto-title{
                                font-size: 20px;
                            }
                            .howto-video-title{
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1099px){
    .how-to-wrapper{
        .container{
            .main-howto{
                .row{
                    .video-info{
                        padding-top: 15%;
                        .info-inner-wrapper{
                            padding-left: 15%;
                            max-width: 75%;
                            .howto-title{
                                font-size: 18px;
                            }
                            .howto-video-title{
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px){
    .how-to-wrapper{
        .container{
            .col-md-6, .col-md-4{
                padding: 0 !important;
            }
            .main-howto{
                margin-top: 0;
                .row{
                    margin: 0;
                    .video-info{
                        padding: 0 !important;
                        .info-inner-wrapper{
                            padding: 40px;
                            text-align: center;
                            .howto-title{
                                font-size: 18px;
                            }
                            .howto-video-title{
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
        }
    }

    #howto-videos{
        #mini-howtos{
            .row{
                margin-top: 30px;
                .category-title{
                    padding-left: 0 !important;
                }
                .howto-video-container{
                    margin-top: 40px;
                    .btn_playVideo{
                        height: 275px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 765px) {
    .howto-video-container {
        min-height: 240px;
    }
}
@media only screen and (min-width: 992px) {
    .howto-video-container {
        min-height: 245px;
    }

    .how-to-wrapper{
        .container{
                .row{
                    
                    .col-md-6{
                        margin: 0px 4px;
                        width: calc(50% - 8px);
                    }
                }

            .main-howto{
                .row{
                    .video-info{

                        .info-inner-wrapper{
                        }
                    }
                }
            }
        }
        margin-bottom: -45px;
    }
}