

@media (max-width: 1280px) {
    .inner {
        height: 445px;
    }

    .inner.scaling {
        height: auto;
    }
}

@media (min-width: 1280px) and (max-width: 1600px) {
    .inner {
        height: 559px;
    }

    .inner.scaling {
        height: auto;
    }
}

@media (min-width: 1600px) and (max-width: 2000px) {
    .inner {
        height: 694px;
    }

    .inner.scaling {
        height: auto;
    }
}

@media (min-width: 2000px) {
    .inner {
        // height: 800px;
    }

    .inner.scaling {
        // height: 800px;
        padding-bottom: 0;
    }
}

@media (max-width: 1024px) {
    .navbar-header {
        float: none;
    }

    .navbar-left, .navbar-right {
        float: none !important;
    }

    .navbar-toggle {
        display: block;
        margin-top: 22px !important;
    }

    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    }

    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }

    .navbar-collapse.collapse {
        display: none !important;
    }

    .navbar-nav {
        float: none !important;
        margin-top: 7.5px;
    }

    .navbar-nav > li {
        float: none;
    }

    .navbar-nav > li > a {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .collapse.in {
        display: block !important;
    }
}
