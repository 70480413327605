@media only screen and (max-width: 768px) {
    .sm-text-center {
        text-align: center;
    }
    .sm-text-left {
        text-align: left;
    }
    .sm-text-right {
        text-align: right;
    }
}