#BVRRSummaryContainer {
    min-height: 32px;
}
.bv-cv2-cleanslate .bv-shared .bv-primarySummary-rating-container .bv-rating-stars-container .bv-rating-stars-on {
    color: #f8c712 !important;
}
.bv-cv2-cleanslate .bv-shared .bv-primarySummary-rating-container .bv-rating-stars-container .bv-rating-stars {
    letter-spacing: 2px !important;
    font-size: 26px !important;
}

.bv-cv2-cleanslate .bv-summary-bar .bv-rating.bv-text-link {
    color: black !important;
}

.bv-cv2-cleanslate .bv-summary-bar .bv-rating-ratio-count {
    color: black !important;
}

input, textarea {
    font-family: Arial, Helvetica, sans-serif !important;
}