
#home-howto-content .h48 {
    display: block;
    margin-top: 5px;
}
#home-video-content {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 50px;
    padding-bottom: 50px;
}

#home-howto{
    background: #23c7eb !important;
    .container-fluid{
        height: 100%;
        .row{
            height: 100%;

        }
    }

    #home-howto-content {
        text-align: center;
        position: relative;
        line-height: 1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0;
        

        .h48{
            line-height: 1;
            max-width: 500px;
            margin: 0 auto;
            text-align: left;
            font-size: 37px;
        }
    
        .buttons{
            text-align: left;
            max-width: 500px;
            margin: 0 auto;
            margin-top: 40px;
            // vertical-align: middle;
            display: flex;
            flex-direction: row;
            // justify-content: center;
            align-items: center;

            .hm-button-rounded{
                text-transform: none;
                font-size: 18px;
                // display: inline-block;
                padding: 17px 27px;
                margin-right: 20px;
                font-weight: 300;
            }
        
            .hm-button-square-outline{
                color: white;
                border: 3px solid white;
                // padding: 10px 22px;
                height: 50px;
                width: 125px;
                position: relative;
                font-size: 24px;
                // display: inline-block;
                margin-right: 20px;
                /* overflow: hidden; */
                display: flex;
                flex-direction: column-reverse;
                padding: 0px 20px;
                display: none;

                .weight {
                    display: block;
                    position: absolute;
                    width: 125px;
                    padding: 10px 0;
                    line-height: 1;
                    transform: translateY(-100%);

                        &:nth-child(1){
                            transform: translateY(0);
                        }
                    &.scrolling{
                        
                    }

                    &.showing {
                        transform: translateY(0px);
                        opacity: 1;
                        transition: 0.5s ease transform, 0.25s ease opacity;
                    }

                    &.leaving {
                        transform: translateY(20%);
                        opacity: 0;
                        transition: 0.5s ease transform, 0.25s ease opacity;
                    }

                    &.reset {
                        transition: none;
                        transform: translateY(-20%);
                        opacity: 0;
                    }
                }


                @for $i from 1 to 1000 {
                    .scrolling:nth-child(#{$i}){
                        transition-delay: $i * 3s !important;
                        transition: .3s linear transform;
                        transform: translateY(0%);
                        transform-origin: top center;
                    }
                }
            }
        }
    
    }

    .video-background-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        overflow: hidden;

        video {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            height: 100%;
        }
    }
}

@media only screen and (min-width: 768px) {
    #home-howto {
        .video-background-wrapper {
            video {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                height: auto;
                width: 100%;
            }
        }
    }
}

#home-as-seen-on-tv {
    position: relative;
    min-height: 438px;
    padding-top: 90px;
    padding-bottom: 90px;
    color: white;
    background-size: cover;
    background-image: url('/images/as_seen_on_tv_context.jpg');
    background-position-x: 95%;
}

#featured-content {

    .container-fluid {
        padding: 0px;

        .featured-content-section {
            height: 450px;
            padding: 0px;
            background-size: cover;
            background-position: center;

            .darkMask {
                background-color: rgba(0,0,0,0.4);
            }

            .featured-content-section-inner {
                position: absolute;
                width: 100%;
                max-width: 330px;
                left: 50%;
                transform: translate(-50%, -50%);
                top: 50%;
                height: 240px;
                text-align: center;
                color: white;

                .preheader {
                    font-weight: bold;
                    text-transform: uppercase;
                    letter-spacing: 4px;
                }

                .h38 {
                    line-height: 1;
                }
                p {
                    font-weight: 100;
                    font-size: 16px;

                }

                .hm-button {
                    position: absolute;
                    bottom: 0px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            #as-seen-on-tv {
                position: absolute;
                bottom: 25px;
                left: 25px;
                width: 75px;
            }
        }
    }
}

@media only screen and (min-width: 768px) {

    #home-howto-content {
        text-align: left;
        padding-top: 0px;
        padding-bottom: 0px;
    }
    #home-as-seen-on-tv {
        background-position: inherit;
        .darkMask {
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+85,000000+85&0+31,0.4+85 */
            background: -moz-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 31%, rgba(0,0,0,0.4) 85%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 31%,rgba(0,0,0,0.4) 85%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 31%,rgba(0,0,0,0.4) 85%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#66000000',GradientType=1 ); /* IE6-9 */
        }
    }
}
@media only screen and (min-width: 320px) {


    #home-howto-content {
        color: #fff;
        margin-top: 20px;
        padding-left: 30px;
    }

    #home-howto-content .h48 {
        line-height: 36px;
        font-size: 2.0em !important;
    }

}

@media only screen and (min-width: 375px) {


    #home-howto-content {
        color: #fff;
        margin-top: 20px;
        padding-left: 30px;
    }

    #home-howto-content .h48 {
        line-height: 36px;
        font-size: 2.3em !important;
    }

}

@media only screen and (min-width: 425px) {

    #home-howto-content {
        color: #fff;
        margin-top: 20px;
    }

    #home-howto-content .h48 {
        line-height: 47px;
        font-size: 3.0em !important;
    }

}

@media screen and (max-width: 991px){
        #home-howto {
        position: relative;
        height: 500px;
        // background: url("/images/lavenderHang_2_768.jpg") no-repeat 10% 50%;
        background-size: cover;
    
    }
}


@media only screen and (min-width: 992px) {
    #home-howto {
        position: relative;
        height: 500px;
        // background: url("/images/lavenderHang_2_768.jpg") no-repeat 10% 50%;
        background-size: cover;
        // display: flex;
        // flex-direction: row;
        // .video-section{
        //     width: 70%;
        // }
        // #home-howto-content{
        //     width: 30%;
        // }
    }


    #home-howto-content {
        // margin-top: 145px;
        color: #fff;
    }

}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
    #home-howto-content {
        // margin-top: 145px;
        color: #fff;
    }

    #home-howto-content .hm-divider {
        margin: 15px 0 25px 0;
    }

}

@media only screen and (min-width: 1440px) and (max-width: 2560px) {

    #home-howto-content {
        // margin-top: 145px;
        color: #fff;
    }

    #home-howto-content .hm-divider {
        margin: 15px 0 25px 0;
    }

    #home-howto-content .h48 {
        line-height: 47px;
        font-size: 3.8em !important;
    }

}

@media only screen and (min-width: 2560px) {


    #home-howto-content {
        // margin-top: 145px;
        color: #fff;
    }

    #home-howto-content .hm-divider {
        margin: 15px 0 25px 0;
    }

    #home-howto-content .h48 {
        line-height: 47px;
        font-size: 3.8em !important;
    }
}

.home-sectionblock {
    display: table;
    text-align: center;
    height: 400px;
}

.home-sectionblock img {
    display: block;
    margin-bottom: 20px;
}

.home-sectionblock > a {
    display: table-cell;
    vertical-align: middle;
}

.home-sectionblock img {
    margin: 0 auto;
}

.home-sectionblock .hm-button-product strong {
    text-transform: lowercase;
}

.home-sectionblock .hm-button-product {
    font-size: 1.125em;
}

#home-howto-content .hm-divider {
    top: 0;
}

#home-sweeps-content {
    padding: 100px 20px 0 100px;
}

#home-sweeps-content p {
    padding: 15px 0 25px 0;
}

#home-whereToBuy {
    position: relative;
    background-color: $gray-light;
    padding: 25px 0 50px 0;
}

#home-whereToBuy img {
    margin: 0 auto;
}

/*
.inner {
                width: 100%;
                background-image: url('/images/carousel/Homepage-Floating.jpg');
                background-size: cover;
                background-position: center;
            }
            .inner.scaling {
                padding-bottom: 43%;
            }
*/
#slider-floatingShelves {
    background: url("/images/carousel/Homepage-Floating.jpg") no-repeat 50% 50%;
    background-size: cover;
    min-height: 439px;
}
