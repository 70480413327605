.faq {
    position: relative;
    margin-bottom: 60px;
}

.frequentlyaskedquestions{
    .container-fluid-boom{
        #main{
            & > .container{
                max-width: 1200px;
                width: 100%;
            }
        }
    }
    #hm-contentpage-title{
        margin-top: 50px;
    }
    .faq-navigation-wrapper{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 50px;

        .faq-section-title{
            padding: 10px;
            letter-spacing: 1px;
            font-size: 16px;
            text-transform: uppercase;
            font-weight: 400;
            color: #a4aaad;
            transition: .5s ease color;
            padding-bottom: 15px;

            .text{
                position: relative;
                &::after{
                    content: "";
                    position: absolute;
                    height: 2px;
                    background-color: #00afec;
                    width: 100%;
                    bottom: -7px;
                    left: 0;
                    transform: scaleX(0);
                    transform-origin: center center;
                    transition: .3s ease transform;
                }
            }
            
            &:hover{
                color: #242039;
                transition: .3s ease color;
                cursor: pointer;
            }

            &.active{
                color: #242039;

                .text{
                    &::after{
                        transform: scaleX(1);
                        transform-origin: center center;
                        transition: .3s ease transform;
                    }
                }
            }
        }
    }
    .faq-flex-row{
        &.faq-section{
            height: 0;
            overflow: hidden;
            .faq{
                opacity: 0;
                transform: translateY(35px);
            }

            &.active{
                height: 100%;
                @for $i from 1 through 100 {
                    .faq:nth-child(#{$i}){
                        opacity: 1;
                        transform: translateY(0);
                        transition: .55s ease opacity, .3s ease transform;
                        transition-delay: .10s * $i;
                    }
                }
            }
        }
        .faq{
            position: relative;
            overflow: hidden;
            background-color: #fbfbfb;
            border: 1px solid #efefef;
            
            &.faq-text-content{
                
                .headline{
                    line-height: 1.4;
                    font-size: 24px;
                }
                .body{
                    line-height: 1.8;
                    font-size: 16px;
                }
                
            }
            &.faq-video-content {
                    // position: absolute;
                    // top: 0;
                    // left: 0;
                    // height: 100%;
                    // width: 100%;
                    padding-bottom: 0;

                }
        }
    }
    .faq-navigation-wrapper{
        text-align: center;
        
        .faq-section-title{
            
        }
    }
}

@media screen and (min-width: 992px){
    .frequentlyaskedquestions{
        .faq-flex-row{
            display: flex;
            // flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            // justify-content: center;

            .faq{
                width: 50%;
                // height: 500px;
                flex: 1 0 auto;
                margin-bottom: 20px;
                &.faq-text-content{
                    // padding-bottom: 50%;
                    height: 0;
                    padding-bottom: 50%;
                    position: relative;

                    .inner-content{
                        position: absolute;
                        width: 80%;
                        left: 50%;
                        top: 50%;
                        transform: translateY(-50%) translateX(-50%);
                    }
                    
                    h4{
                        margin-bottom: 20px;
                    }
                }
                &.faq-video-content{
                    padding-bottom: 50%;
                }
                &.faq-video-content {
                    
                }
            }
        }

        .faq-navigation-wrapper{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

    }
}

@media screen and (max-width: 991px){
    .frequentlyaskedquestions{
        .faq-flex-row{
            max-width: 650px;
            margin: 0 auto;
            .faq{
                margin-bottom: 0;
                &.faq-text-content{
                    padding: 40px;
                    margin-top: 20px;
                }
                &.faq-video-content{
                    padding-top: 100%;
                }
            }
        }
    }
}