.container-home-max-width{
    max-width: 1600px !important;
    margin: 0 auto;
    width: 100%;
}

.hm-grid {

    .hm-grid-title {
        margin-top: 15px;
        padding: 15px 25px;
        text-align: center;
        text-transform: uppercase;
    }
    
    .hm-grid-cell {
        position: relative;
        display: block;
        text-align: center;
        height: 0px;
        padding-bottom: 85%;
        border: 1px solid #efefef;
        transition: 0.25s ease background-color, 0.25s ease border-color;
        overflow: hidden;

        .hm-grid-copy-wrapper {
            position: absolute;
            bottom: 25px;
            left: 50%;
            transform: translateX(-50%);
            display: table;
            max-width: 75%;

            .hm-button-box {
                display: table-cell;
                vertical-align: middle;
                font-size: 1em;
                transition: 0.25s ease color, 0.25s ease border-color;
                font-weight: 500;
                letter-spacing: 1px;

                strong {
                    text-transform: lowercase;
                }
            }
            .hm-button-box:hover {
                background-color: transparent;
            }

            .hm-grid-description {
                display: table-cell;
                padding-left: 15px;
                text-align: left;
                vertical-align: middle;
                transition: 0.25s ease color;
            }
        }

        .hm-grid-ratio-container {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;

            .hm-grid-image-wrapper {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transform: scale(1);
                transform-origin: center center;
                transition: .5s ease transform;
        
                img {
                    display: block;
                    margin: 0 auto;
                    margin-bottom: 20px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    max-width: 65%;
                    max-height: 75%;
                }
            }
        }
    }

    .hm-grid-cell:hover {
        // background-color: $color-wallHangers;
        .hm-grid-image-wrapper{
            transform: scale(1.04);
            transform-origin: center center;
            transition: .5s ease transform;
        }    
    }
}

.homepage {
    .hm-grid {
        // margin: 0 auto;
        // max-width: 1450px;
        .hm-grid-title {
            padding: 15px 50px;
        }
        
        .hm-grid-cell {
            position: relative;
            display: block;
            text-align: center;
            height: 0px;
            // padding-bottom: 85%;
            border: 0;
            transition: 0.25s ease background-color, 0.25s ease border-color;
    
            .hm-grid-copy-wrapper {
                position: absolute;
                top: 8%;
                left: 5%;
                transform: none;
                display: block;
                max-width: 100%;
    
                .hm-button-box {
                    font-size: 1.8em;
                    transition: 0.25s ease color, 0.25s ease border-color;
                    letter-spacing: 0;
                    display: block;
                    border: 0;
                    padding:0;
                    line-height: 1;
                    text-align: left;
                    &.pre-headline{
                        font-weight: 300;
                        color: #242039;
                    }

                    &.headline{
                        font-weight: 500;
                        color: #242039;
                    }
    
                    strong {
                        text-transform: lowercase;
                    }
                }
                .hm-button-box:hover {
                    background-color: transparent;
                }
    
                .hm-grid-description {
                    display: table-cell;
                    padding-left: 15px;
                    text-align: left;
                    vertical-align: middle;
                    transition: 0.25s ease color;
                }
            }
    
            .hm-grid-ratio-container {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
    
                .hm-grid-image-wrapper {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
            
                    img {
                        display: block;
                        margin: 0 auto;
                        margin-bottom: 20px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        max-width: 65%;
                        max-height: 75%;
                    }
                }
            }

            &.square-cell{
                padding-bottom: 40%;
                .headline, .pre-headline{
                    font-size: 2em;
                }
            }
        }
    }
    .two-col{
        .hm-grid-cell{
            .hm-grid-ratio-container{
                .hm-grid-image-wrapper{
                    &.product{
                        background-size: contain;
                    }
                }
            }
        }
    }
    .three-col{
        .hm-grid-cell{
            .hm-grid-ratio-container{
                .hm-grid-image-wrapper{
                    background-size: contain;
                }
            }
        }
        .headline, .pre-headline{
            font-size: 1.5em !important;
        }
    }
}
@media only screen and (min-width: 768px) {
    .hm-grid {
        .hm-grid-title {
            padding: 15px 50px;
        }
        .hm-grid-cell {
            padding-bottom: 40%;
        }
    }
}

@media only screen and ( min-width: 992px ) {
    .hm-grid {
        .hm-grid-title {
            text-align: left;
        }
        .hm-grid-cell {
            padding-bottom: 28%;
        }
    }
}

@media only screen and ( min-width: 992px ) {
    .hm-grid {
        .hm-grid-cell:hover {
            .hm-button-product {
                color: white;
                border-color: white;
            }
            .hm-grid-description {
                color: white;
            }
        }
    }

    .homepage{
        .hm-grid{
            .two-col{
                display: flex;
                flex-direction: row;
                .hm-grid-cell{
                    width: 50%;
                }
            }
        }
    }

    // #wall-hangers-hm-grid .hm-grid-cell:hover,
    // #wall-hangers-hm-grid.hm-grid-cell:hover {

    //     .hm-grid-copy-wrapper{
    //         .hm-button-box{
    //             color: $color-wallHangers;
    //         }
    //     }
    // }
    
    // #key-rails-hm-grid .hm-grid-cell:hover,
    // #key-rails-hm-grid.hm-grid-cell:hover {
        
    //     .hm-grid-copy-wrapper{
    //         .hm-button-box{
    //             color: $color-keyRails;
    //         }
    //     }
    // }
    
    // #decorative-hooks-hm-grid .hm-grid-cell:hover,
    // #decorative-hooks-hm-grid.hm-grid-cell:hover {
    
    //     .hm-grid-copy-wrapper{
    //         .hm-button-box{
    //             color: $color-decorativeHooks;
    //         }
    //     }
    // }
    
    // #floating-shelves-hm-grid .hm-grid-cell:hover,
    // #floating-shelves-hm-grid.hm-grid-cell:hover {
    //     .hm-grid-copy-wrapper{
    //         .hm-button-box{
    //             color: $color-floatingShelves;
    //         }
    //     }
    // }
    
    // #hook-rails-hm-grid .hm-grid-cell:hover,
    // #hook-rails-hm-grid.hm-grid-cell:hover {
    //     .hm-grid-copy-wrapper{
    //         .hm-button-box{
    //             color: $color-hookRails;
    //         }
    //     }
    // }

}

@media screen and (max-width: 991px){
    .homepage{
            .hm-grid{
                .two-col{
                    display: flex;
                    flex-direction: column;
                    &.two{
                        flex-direction: column-reverse;
                    }

                    .hm-grid-cell{
                        padding-bottom: 75%;
                    }
                }
            }
    }
}