/*=== VARIABLES ===*/
$primary-color: #242039;
$secondary-color: #a4aaad;

$blue: #0098CE;
$blue-dark: #006abc;

$gray-light: #ececec;

$color-wallHangers: #0098ce;
$color-decorativeHooks: #8a6ad4;
$color-keyRails: #3ad531;
$color-hookRails: #ffe900;
$color-floatingShelves: #ff7376;

$btn-padding: 10px;

$text-gray: #a4aaad;

@function tint($primary-color, $percentage) {
    @return mix(white, $primary-color, $percentage);
}
