.where-to-buy {
    .inner {
        height: unset;
        padding: 25px;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;

        .selections-wrapper-outer {
            margin-bottom: 50px;

            h2 {
                font-size: 14px;
                font-weight: 500;
                text-transform: uppercase;
                letter-spacing: 2px;
                margin-bottom: 25px;
            }
            .selections-wrapper-inner {
                position: relative;
                width: 100%;
            }

            .selections-wrapper {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                // border-top: 1px solid #d9d9d9;
                // border-left: 1px solid #d9d9d9;

                .selection-wrapper {
                    // min-width: 33.33%;
                    // flex: 1;
                    padding-top: 15px;
                    // border: 1px solid #d9d9d9;
                    // border-left: 0px;
                    // border-top: 0px;
                    // transition: 0.25s ease background-color, 0.25s ease color;
                    // background-color: #777777;
                    background-color: #fbfbfb;
                    transition: .3s ease background-color;
                    margin: 5px;
                    cursor: pointer;

                    .selection-image{
                        transform: scale(1) translate(-50%, -50%);
                        transition: .3s ease transform;
                        transform-origin: center center;
                        
                    }

                    &:hover {
                        background-color: #f1f1f1;
                        transition: .3s ease background-color;

                        .selection-image {
                            transform: scale(1.05) translate(-48%, -48%);
                            transition: .5s ease transform;
                            transform-origin: center center;
                        }
                    }

                    &.selected {
                        background-color: #f1f1f1;
                        transition: .3s ease background-color;

                        .selection-image-wrapper {

                        }
                    }

                    .selection-image-wrapper {
                        position: relative;
                        height: 100px;
                        width: 100%;
                        overflow: hidden;

                        .selection-image {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            max-height: 100%;
                            padding-bottom: 50px;
                            max-width: 100%;

                            &.tall {
                                // left: 18%;
                            }
                            &.wide {
                                
                            }
                            &.wide-reverse {
                                // left: 15%;
                                // top: -20%;
                            }
                            &.square {
                                left: 50%;
                                top: 50%;
                                // transform: translate(-50%, -50%);
                                max-width: 85%;
                                max-height: 100%;
                            }
                        }
                    }
                }

            }
        }
        .selection-step-wrapper {
            height: 0px;
            transition: 0.5s ease height;
            margin-bottom: 50px;

            .selections-wrapper-outer {
                position: relative;
                height: 0px;
                overflow: hidden;
                margin-bottom: 0px;

                .selections-wrapper-inner {
                    top: 0;
                    left: 0;
                    width: 100%;
                    transition: 0.35s ease opacity;
                    opacity: 0;
                }

                &.active {
                    height: unset;

                }
                &.showing {
                    .selections-wrapper-inner {
                        opacity: 1;
                    }
                }
            }
        }

        .step-one{
            .selection-label {
                font-size: 1.7em;
                transition: 0.25s ease color, 0.25s ease border-color;
                letter-spacing: 0;
                display: block;
                border: 0;
                padding: 0;
                line-height: .75;
                text-align: left;
                // padding-top: 20px;
            }
        }

        .step-two{
            .selection-label {
                font-size: 1.3em;
                transition: 0.25s ease color, 0.25s ease border-color;
                letter-spacing: 0;
                display: block;
                border: 0;
                padding: 0;
                line-height: .75;
                text-align: left;
                // padding-top: 20px;
            }
        }


    }
}

@media only screen and (min-width: 768px) {
    .where-to-buy {
        .inner {
            padding: 50px;
            .step-one{
                &.selections-wrapper-outer {
                    .selections-wrapper {
                        .selection-wrapper {
                            // flex: initial;
                            min-width: unset;
                            padding-top: 25px;

    
                            .selection-label {
                                padding: 25px 25px 5px 25px;
                                // font-size: 14px;
                            }
                        }

                        @for $i from 1 through 2 {
                            .selection-wrapper:nth-child(#{$i}) { 
                                flex-basis: calc(50% - 10px);
                                .selection-image-wrapper {
                                    height: 350px;
                                    .selection-image{
                                        padding: 10px 0px 50px 0px;
                                    }
                                    .wide {
                                        // max-width: 110%;
                                    }
                                }
                            }
                        }

                        @for $i from 3 through 10 {
                            .selection-wrapper:nth-child(#{$i}) { 
                                flex-basis: calc(33.33% - 10px);
                                .selection-image-wrapper {
                                    height: 250px;
                                    .selection-image{
                                        padding: 10px 20px 50px 20px;
                                    }
                                    .wide {
                                        // max-width: 110%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .step-two{
                .selections-wrapper-outer {
                    .selections-wrapper {
                        .selection-wrapper {
                            // flex: initial;
                            flex: 1 1 calc(33.3% - 10px);
                            // min-width: unset;
                            padding-top: 25px;
    
                            .selection-image-wrapper {
                                height: 255px;
                                .selection-image{
                                    padding-bottom: 15px;
                                }
                            }
    
                            .selection-label {
                                padding: 5px 25px;
                                
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .where-to-buy {
        .inner {
            padding: 0px;
            .step-one{
                &.selections-wrapper-outer {
                    .selections-wrapper {
                        .selection-wrapper {
                            // flex: initial;
                            min-width: unset;
                            padding-top: 25px;
    
                            .selection-label {
                                padding: 25px 25px 5px 25px;
                                // font-size: 14px;
                            }
                        }

                        @for $i from 1 through 2 {
                            .selection-wrapper:nth-child(#{$i}) { 
                                flex-basis: 100%;
                                .selection-image-wrapper {
                                    height: 200px;
                                    .selection-image{
                                        padding: 10px 0px 20px 0px;
                                    }
                                    .wide {
                                        // max-width: 110%;
                                    }
                                }
                            }
                        }

                        @for $i from 3 through 10 {
                            .selection-wrapper:nth-child(#{$i}) { 
                                flex-basis: 100%;
                                .selection-image-wrapper {
                                    height: 200px;
                                    .selection-image{
                                        padding: 10px 20px 20px 20px;
                                    }
                                    .wide {
                                        // max-width: 110%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .step-two{
                .selections-wrapper-outer {
                    .selections-wrapper {
                        .selection-wrapper {
                            // flex: initial;
                            flex-basis: 100%;
                            // min-width: unset;
                            padding-top: 25px;
    
                            .selection-image-wrapper {
                                height: 255px;
                                .selection-image{
                                    padding-bottom: 15px;
                                }
                            }
    
                            .selection-label {
                                padding: 5px 25px;
                                
                            }
                        }
                    }
                }
            }
        }
    }
}