.hm-button {
    padding: $btn-padding;
    transition: 0.25s ease background-color;
    font-weight: 400;
}

.hm-button:hover {
    border-bottom: 3px solid $blue;
}

.hm-button-box {
    padding: $btn-padding;
    border: 1px solid #242039;
}

.hm-button-box:hover {
    color: #fff;
    background-color: $blue;
}

.hm-button-box strong {
    font-weight: 700;
}

.hm-button-rounded {
    padding: $btn-padding 30px;
    color: #fff;
    background-color: $blue;
    border-width: 0 !important;
    border-radius: 25px;
    text-transform: uppercase;
    display: inline-block;
    font-size: 12px;
    letter-spacing: 1px;
    transition: 0.25s ease background-color;
    cursor: pointer;

    &.disabled {
        opacity: 0.25;
        cursor: not-allowed;
    }
}

.hm-button-rounded:hover {
    color: #fff;
    background-color: $blue-dark;
}

#footer .hm-button-box {
    display: block;
}

#footer .hm-button-box:hover {
    background-color: none !important;
}

#footer .hm-button-box span {
    padding: $btn-padding; /*border:1px solid $primary-color;*/

    text-transform: uppercase;
}

#footer .hm-button-box span:hover {
    color: #fff;
    background-color: $blue;
    border-width: 0 !important;
}

