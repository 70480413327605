.influencers{
    #influencer-hero{
        position: relative;
        overflow: hidden;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        .darkMask{
            background: rgba(0, 0, 0, 0.35);
            position: absolute;
        }
        .container{
            .row{
                .headline-content{
                    .slider-content{
                        .headline{
                            color: white !important;
                            max-width: 550px;
                            font-size: 50px;
                            margin: 0 auto;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
    .hm-grid{
        .container-fluid{
            .row{
                .two-col{
                    .hm-grid-cell{
                        background-color: #f1f1f1;
                        border: 0;
                        margin: 2.5px;
                        .hm-grid-ratio-container{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;

                            .hm-grid-content-wrapper{
                                p{
                                    line-height: 1.8;
                                }
                            }
                            .hm-grid-image-wrapper{
                                background-size: cover;
                                background-position: bottom;
                                background-repeat: no-repeat;
                            }
                        }
                    }
                }
            }
        }
    }
    #influencer-product-grid{
        max-width: 1595px;
        margin: 0 auto;
        padding: 40px 0;
        margin-top: 2.5px;
        background-color: #f1f1f1;
        .title-wrapper{
            font-size: 24px;
            text-align: center;
            padding: 20px 20px 0px 20px;
        }
        .inner-product-wrapper{
            .category-entry{   
                padding-top: 30px !important;

                &:hover{
                    background-color: #f1f1f1;
                    transition: .3s ease background-color;
                    .category-image{
                        transform: scale(1.1);
                        transition: .3s ease transform;
                    }
                }

                .category-image{
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                    transform: scale(1);
                    transition: .3s ease transform;
                }
                .category-title{
                    text-align: center;
                    padding: 20px;
                    p{
                        display: inline-block;
                    }
                }
            }
        }
    }
    #influencer-social{
        padding: 40px 20px;
        margin-top: 40px;
        .social-title{
            text-align: center;
            font-size: 24px;
            padding-bottom: 20px;
            padding-top: 30px;
            a{
                transition: .3s ease color;
                &:hover{
                    color: #686868;
                    transition: .3s ease color;
                }
            }
        }

        .social-icons{
            text-align: center;
            margin: 0 auto;
            width: 100%;
            height: 23px;
            .nav-social-icon{
                float: none;
                display: inline-block;
                transform: scale(1);
                transition: .3s ease transform;

                &:hover{
                    transform: scale(1.2);
                    transition: .3s ease transform;
                }
            }
        }
    }
}

@media screen and (min-width: 1200px){
    .influencers{
        .hm-grid{
            .container-fluid{
                .row{
                    .two-col{
                        .hm-grid-cell{
                            .hm-grid-ratio-container{
                                .hm-grid-content-wrapper{
                                    p{
                                        font-size: 24px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px){
    .influencers{
        .hm-grid{
            .container-fluid{
                .row{
                    .two-col{
                        .hm-grid-cell{
                            .hm-grid-ratio-container{
                                .hm-grid-content-wrapper{
                                    p{
                                        font-size: 20px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1100px){
    .influencers{
        #influencer-hero{
            .container{
                .row{
                    padding: 175px 20px;
                    .headline-content{
                        .slider-content{
                            .headline{
                            }
                        }
                    }
                }
            }
        }
        .hm-grid{
            .container-fluid{
                .row{
                    .two-col{
                        .hm-grid-cell{
                            padding-bottom: 32%;
                            width: calc(50% - 5px);
                            .hm-grid-ratio-container{
                                .hm-grid-content-wrapper{
                                    max-width: 80%;
                                    p{
                                    }
                                }
                                .hm-grid-image-wrapper{

                                }

                                
                            }
                        }
                    }
                }
            }
        }
        #influencer-product-grid{
            .inner-product-wrapper{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .category-entry{
                    width: 20%;
                    padding: 10px;
                    .category-image{
                        height: 150px;
                        width: 100%;
                    }
                    .category-title{
        
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1100px){
    .influencers{
        #influencer-hero{
            .container{
                .row{
                    padding: 175px 20px;
                    .headline-content{
                        .slider-content{
                            .headline{
                            }
                        }
                    }
                }
            }
        }
        .hm-grid{
            .container-fluid{
                .row{
                    .two-col{
                        .hm-grid-cell{
                            width: calc(50% - 5px);
                            padding-bottom: 45%;
                            .hm-grid-ratio-container{
                                .hm-grid-content-wrapper{
                                    max-width: 80%;
                                    p{
                                        font-size: 18px;
                                    }
                                }
                                .hm-grid-image-wrapper{

                                }

                                
                            }
                        }
                    }
                }
            }
        }
        #influencer-product-grid{
            .inner-product-wrapper{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                .category-entry{
                    width: 33%;
                    padding: 10px;
                    .category-image{
                        height: 150px;
                        width: 100%;
                    }
                    .category-title{
        
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px){
    .influencers{
        #influencer-hero{
            .container{
                .row{
                    padding: 100px 20px;
                    .headline-content{
                        .slider-content{
                            .headline{
                            }
                        }
                    }
                }
            }
        }
        .hm-grid{
            .container-fluid{
                .row{
                    .two-col{
                        &.two{
                            flex-direction: column-reverse;
                            display: flex;
                            width: 100%;
                        }
                        .hm-grid-cell{
                            // min-height: 200px;
                            height: auto;
                            padding-bottom: 0;
                            overflow: visible;
                            padding: 0;
                            .hm-grid-ratio-container{
                                position: relative;

                                .hm-grid-content-wrapper{
                                    padding: 50px 20px;
                                    p{
                                        font-size: 18px;
                                    }
                                }
                                &.image-ratio-wrapper{
                                    // min-height: 200px;
                                    height: 100%;
                                    padding-bottom: 50%;
                                    .hm-grid-image-wrapper{

                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        #influencer-product-grid{
            padding: 20px;
            .inner-product-wrapper{
                .category-entry{   
                
                    .category-image{
                        height: 200px;
                        max-width: 425px;
                        margin: 0 auto;
                    }
                    .category-title{
        
                    }
                }
            }
        }
    }
}