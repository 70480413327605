.h12 {
    font-size: .75em !important;
}

.h14 {
    font-size: 1em !important;
}

.h18 {
    font-size: 1.2em !important;
}

.h20 {
    font-size: 1.4em !important;
}

.h24 {
    font-size: 1.5em !important;
}

.h28 {
    font-size: 2em !important;
}

.h38 {
    font-size: 2.7em !important;
}

.h48 {
    font-size: 3.4em !important;
}

.heading::after {
    display: block;
    content: url("/images/hm-divider.png");
}

// .heading-white::after {
//     display: block;
//     content: url("/images/hm-divider-white.png");
// }

.heading-section {
    display: block;
    text-transform: uppercase;
}

.b100 {
    font-weight: 100;
}

.b200 {
    font-weight: 200;
}

.b300 {
    font-weight: 300;
}

.b400 {
    font-weight: 400;
}

.b500 {
    font-weight: 500;
}

.b600 {
    font-weight: 600;
}

.b700 {
    font-weight: 700;
}
