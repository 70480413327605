.navbar-default {
    background-color: #fff;
    border-width: 0;
}
#hm-navigation {
    position: relative;
    background-color: #fff;
    z-index: 3;
}
.navbar-brand img {
    height: 80%;
}
@media only screen and (min-width: 768px) {
    .navbar-brand img {
        height: 100%;
    }
}

.hm-nav-col a {
    line-height: 118px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 1px;
}
.hm-nav-col .hm-button {
    position: relative;
}
.hm-nav-col .hm-button:hover {
    border-bottom: 0px;
}
.hm-nav-col .hm-button:after {
    position: absolute;
    bottom: -3px;
    left: 0;
    height: 3px;
    width: 100%;
    transition: 0.25s ease transform;
    transform: scaleX(0);
    content: '';
    background-color: $blue;
}
.hm-nav-col .hm-button:hover:after {
    transform: scaleX(1);
}
.nav-social-icon {
    display: block;
    float: left;
    margin-right: 15px;
}
.nav-social-icon img {
    width: 20px;
    height: 20px;
}

#navigation-products {
    position: relative;
    width: 1024px;
    margin: 0 auto;
}

.navigation-product {
    position: relative;
    width: 189px;
    float: left;
    text-align: center;
}

.navigation-product img {
    margin: 0 auto;
}
.navigation-product-image img {
    max-width: 100%;
}
.navigation-product-image a {

}
#nav-wallHangers, #nav-decorativeHooks, #nav-keyRails, #nav-hookRails, #nav-floatingShelves {
    position: relative;
    min-height: 132px;
}
@media only screen and (min-width: 992px) {
    #nav-wallHangers, #nav-decorativeHooks, #nav-keyRails, #nav-hookRails, #nav-floatingShelves {
        min-height: 162px;
    }
}

#products-nav{
    .products-nav-product{
        .navigation-product-image{
            img{
                transform: scale(1);
                transition: .5s ease transform;
            }
        }
    }
    .products-nav-product:hover{
        background-color: white !important;
        .navigation-product-image{
            img{
                transform: scale(1.1);
                transition: .5s ease transform;
            }
        }
    }
}

#products-nav, #wheretobuy-nav {
    display: none;
    position: absolute;
    background-color: #fff;
    top: 138px;
    z-index: 2;
    width: 100%;
    pointer-events: none;
    transform: translateY(-115%);
    transition: 0.25s ease-out transform;
    box-shadow: 0 3px 6px rgba(0,0,0,0.08), 0 3px 6px rgba(0,0,0,0.13);
}
#products-nav.active-nav,
#wheretobuy-nav.active-nav {
    transform: translateY(0);
    pointer-events: auto;
}
@media only screen and ( min-width: 768px ) {
    #products-nav, #wheretobuy-nav {
        display: block;
    }
}
.navigation-product-category {
    position: absolute;
    width: 100%;
    bottom: 10px;
    left: 0;
}
.products-nav-product {
    position: relative;
    padding: 0 9px 40px 9px;
    transition: 0.25s ease background-color;
}
.nav-logo {
    display: block;
    height: 118px;
}
.nav-logo img {
    display: inline;
    vertical-align: middle;
    max-height: 100%;
}
@media only screen and ( min-width: 768px ) {
    .nav-logo {
        max-width: 85%;
        margin: 0 auto;
    }
}
@media only screen and ( min-width: 992px ) {
    .nav-logo {
        max-width: 65%;
        padding: 10px 0;
        text-align: center;
    }
}
#nav-btn-wtb {
    padding: 1em 1em;
    border: 1px solid black;
    font-weight: 500;
    font-size: 12px;
    white-space: nowrap;
}
// #nav-btn-wtb:hover {
//     background-color: $blue;
//     border-color: $blue;
//     color: white;
// }
#navigation-deactivator {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: black;
    z-index: -1;
    transition: 0.25s ease opacity;
    opacity: 0;
    pointer-events: none;
}

body.active-nav #navigation-deactivator {
    pointer-events: initial;
    z-index: 1;
}
#overflow-cover {
    position: absolute;
    display: none;
    top: -50vh;
    height: 50vh;
    width: 100%;
    left: 0;
    background-color: white;
    z-index: 5;
}
@media only screen and (min-width: 768px) {
    #overflow-cover {
        display: block;
    }
}