.SlidCont {
    padding: 108px 0 58px;
    position: relative;
    z-index: 2;
    overflow: hidden;
}

.slider-bg {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
}

.bgslider { /*margin-bottom:42px;*/
}

#slider-floatingShelves, #slider-floatingShelves .slider-content {
    min-height: 438px;
}

.SlidImg {
    float: left;
    text-align: right;
    width: 50%;
    padding-right: 84px;
    padding-top: 5px;
}

.SlidImg img {
    max-width: 404px;
    min-width: 404px;
    width: auto;
    display: inline-block !important;
}

.SliderCont {
    width: 50%;
    float: left;
    padding-right: 17%;
    padding-left: 35px;
}

.SliderCont .nav-tabs {
    border: none;
}

.SliderCont .nav-tabs li a {
    border: 2px solid #221e35;
    cursor: default;
    background: none;
    color: #221e35;
    border-radius: 0;
    padding: 0px 5px;
    margin-right: 5px;
    font-size: 1.3em;
}

.SliderCont .nav-tabs li a {
    border: 2px solid #221e35;
    cursor: default;
    background: none;
    color: #221e35;
    border-radius: 0;
    padding: 0px 5px;
    margin-right: 5px;
    font-size: 1.3em;
}

/*.SliderCont .nav-tabs li a:hover{border:2px solid #221e35; background:#221e35; color:#FFFF00;}*/
.SliderCont h3 {
    color: #221e35;
    background: url(../images/wave.png) left bottom no-repeat;
    padding-bottom: 30px;
    margin-top: 10px;
    margin-bottom: 3px;
    font-size: 3.5em;
    font-family: "NeoSansStd-Medium", Arial, Helvetica, sans-serif;
}

.SliderCont h4 {
    color: #ffffff;
    padding-bottom: 17px;
    font-size: 4.7em;
    line-height: 46px;
    font-family: "NeoSansStd-Bold", Arial, Helvetica, sans-serif;
}

.SliderCont h4 span {
    display: block;
    font-weight: normal;
    font-family: "NeoSansStd-Regular", Arial, Helvetica, sans-serif;
}

.SliderCont.slide-center {
    padding: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
}

.owl-item .extra-height {
    height: 102%;
}

.owl-item .item img-bg {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
}

a.learnBtnSld {
    padding: 13px 44px;
    border-radius: 5px;
    letter-spacing: 1px;
    font-size: 1.5em;
}

.slide-center .head-line {
    display: inline-block;
    font-weight: 500;
    background: url("../images/wave.png") no-repeat scroll center bottom;
    color: #ffe726;
    font-size: 2.9em;
    margin-top: 30px;
    padding-bottom: 30px;
    font-family: "NeoSansStd-Medium", Arial, Helvetica, sans-serif;
}

.slide-center h4 {
    color: #ffffff;
    padding-bottom: 17px;
    font-size: 4.9em;
    line-height: 46px;
    font-family: "NeoSansStd-Bold", Arial, Helvetica, sans-serif;
    width: 46%;
    margin: 0px auto;
}

.slide-center h4 span {
    display: block;
    font-weight: normal;
    font-family: "NeoSansStd-Regular", Arial, Helvetica, sans-serif;
}

.slide-center .topshift {
    margin-top: 18%;
}

.slide-center.spacing h3 {
    color: #211e35;
}

.slide-center.spacing h4 {
    width: 95%;
}

.slide-center.spacing .topshift {
    margin-top: 23%;
}


.main-carousel {
    margin-bottom: 50px;
}
.carousel-cell {
    position: relative;
    width: 100%; /* full width */
    height: 350px;
    margin-right: 10px;
  }

  .product-detail {
      .carousel-cell {
        height: 0px;
        padding-bottom: 100%;

        &.video-player-wrapper {
            // position: absolute;
            // top: 0;
            // left: 0;
            // height: 100%;
            // width: 100%;

            &.activated {
                &.playing {
                    .video-thumbnail {
                        opacity: 0;
                        pointer-events: none;
                    }
                    .play-icon {
                        opacity: 0;
                        pointer-events: none;
                    }
                }

                &.paused {
                    .video-thumbnail,
                    .play-icon {
                        opacity: 1;
                        pointer-events: auto;
                    }
                }
            }

            .video-thumbnail {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-size: cover;
                background-position: center;
                transition: 0.5s ease opacity;
                opacity: 1;
                z-index: 4;
            }

            .play-icon {
                position: absolute;
                top: calc(50% - 50px);
                left: calc(50% - 50px);
                height: 100px;
                width: 100px;
                border: 3px solid white;
                border-radius: 50%;
                z-index: 5;
                cursor: pointer;
                opacity: 1;

                &:before {
                    position: absolute;
                    top: 50%;
                    left: 55%;
                    transform: translate(-50%, -50%);
                    border-top: 20px solid transparent;
                    border-left: 35px solid white;
                    border-bottom: 20px solid transparent;
                    content: '';
                }
            }
            .player {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }
        }

      }
  }
.carousel-cell img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 100%;
}

.thumbnail-grid {
    margin-top: 25px;
    width: 100%;
}
.thumbnail-cell {
    position: relative;
    width: 100%;
    border: 2px solid #efefef;
    padding-bottom: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.65;
    transition: 0.5s ease opacity, 0.5s ease border-color;
    cursor: pointer;
    margin-bottom: 10px;
}
.thumbnail-cell:hover {
    border-color: #d9d9d9;
    opacity: 0.85;
}
.thumbnail-cell.active {
    border-color: #d9d9d9;
    opacity: 1;
}
@media only screen and ( min-width: 768px ) {
    .flickity-page-dots {
        display: none;
    }
}
@media only screen and ( min-width: 992px ) {
    .main-carousel {
        margin-bottom: 0px;
    }
    .carousel-cell {
        height: 450px;
    }
    .product-detail {
        .carousel-cell {
            height: 0px;
            padding-bottom: 100%;
        }
    }
}