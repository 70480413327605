.blog-list {
    max-width: 1200px;
    margin: 0 auto;

    .inner-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .article-preview {
            flex-basis: calc(100% - 30px);
            margin: 15px;
            background-color: #fafafa;
            box-shadow: 0 1px 3px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.18);

            .preview-image {
                width: 100%;
                padding-bottom: 125%;
                background-size: cover;
                background-position: center;
            }
            .preview-info {
                padding: 25px;

                .preview-title {
                    font-weight: bold;
                    font-size: 18px;
                    margin-bottom: 20px;
                }

                .preview-summary {
                    font-size: 15px;
                }

                .preview-cta {
                    text-transform: uppercase;
                    font-weight: 500;
                    letter-spacing: 1px;
                    font-size: 13px;
                    margin-top: 25px;

                    .arrow {
                        width: 50px;
                        transition: 0.35s ease transform;
                    }
                }
            }

            &:hover {
                .preview-info {
                    .preview-cta {
                        .arrow {
                            transform: translateX(10px);
                        }
                    }
                }
            }
        }
    }
}
.blog-article {
    max-width: 1000px;
    margin: 0 auto;

    .hero-wrapper {
        width: 100%;

        .hero-image {
            width: 100%;
            &.mobile {
                display: block;
            }
            &.large {
                display: none;
            }
        }
    }
    .inner-wrapper {
        padding: 50px 25px;
        padding-bottom: 0px;
        margin: 0 auto;
        max-width: 100%;

        .body {

            .article-title {
                font-weight: bold;
                margin-top: 0px;
                margin-bottom: 20px;
            }

            .divider {
                margin-bottom: 30px;
            }

            .body-content {

                .blog-block {
                    margin-bottom: 50px;

                    img {
                        max-width: 100%;
                    }

                    &.image {
                    }
                    .attribution {
                        font-size: 10px;
                        margin-top: 10px;
                        p {
                            font-size: 10px;
                        }
                    }
                    .responsive-wrapper {
                        position: relative;
                        height: 0;
                        width: 100%;
                        padding-bottom: 56.25%;
                        
                        iframe {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

                .standard-copy {
                    p {
                        line-height: 2;
                        margin-top: 0px;
                    }
                    a {
                        text-decoration: underline;
                    }
                }

                .external-link {
                    display: block;
                    font-style: italic;
                    margin-bottom: 50px;
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

        }

        .author {
            width: 280px;
            flex-shrink: 0;
            margin: 0 auto;
            padding: 0px 25px;
            padding-top: 50px;
            border-top: 1px solid #ccc;

            .author-image {
                width: 100%;
                margin-bottom: 10px;
            }
            .author-info {
                font-size: 16px;
                margin-bottom: 5px;

                .author-name {
                    font-weight: bold;
                }
            }
            .divider {
                margin-bottom: 10px;
            }
            .author-bio {
                font-size: 12px;
                line-height: 1.5;
            }
        }
    }
    .article-list {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 50px;

        .article-navigation {
            display: flex;
            align-items: center;
            flex: 1;

            &.empty {
                visibility: hidden;
                pointer-events: none;
            }

            .article-wrapper {
                .article-link {
                    height: 75px;
                    width: 75px;
                    display: block;
                    background-size: cover;
                    background-position: center;
                }
            }
            .article-navigation-option {
                font-size: 14px;
                text-transform: uppercase;
                letter-spacing: 1px;
                padding: 0px 15px;
            }
            &:first-child {
                justify-content: flex-end;
                margin-right: 5px;

                .article-navigation-option {
                    order: 0;
                }
            }
            &:last-child {
                margin-left: 5px;

                .article-navigation-option {
                    order: 1;
                }
                .article-wrapper {
                    order: 0;
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .blog-list {
        .inner-wrapper {
            .article-preview {
                flex-basis: calc(50% - 30px);
            }
        }
    }
    .blog-article {
        .hero-wrapper {
            .hero-image {
                &.mobile {
                    display: none;
                }
                &.large {
                    display: block;
                }
            }
        }
        .inner-wrapper {
            display: flex;
            max-width: 1000px;
            padding: 75px 50px;
            padding-bottom: 0px;

            .body {
                margin-right: 125px;
            }
            .author {
                width: 230px;
                margin: 0 auto;
                padding: 0px;
                border-top: 0;
            }
        }
        .article-list {
            width: 800px;
            margin: 0 auto;

            .article-navigation {
                width: 500px;
    
                .article-navigation-option {
                    padding: 0px 35px;
                }
    
                .article-wrapper {
        
                    .article-link {
                        width: 120px;
                        height: 100px;
                        display: block;
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 992px) {
    .blog-list {
        .inner-wrapper {
            .article-preview {
                flex-basis: calc(33% - 30px);
            }
        }
    }
}