@media only screen and (max-width: 768px) {
    .home-sectionblock {
        border-bottom: 1px solid #e1e1e1;
    }

    .instructions-content div {
        padding: 0 20px 20px 20px;
    }
}

#product-information-content .modal-dialog {
    width: 830px !important;
    margin: 0 auto !important;
}

#BVRRSummaryContainer {
    position: relative;
    margin-bottom: 25px;
}

#product-name {
    margin-top: 10px;
}

#product-name span {
    font-size: 32px;
    margin-right: 20px;
}

#product-buyNow {
    position: relative;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
    margin-top: 20px;
}

#product-information-content ul {
    list-style: none;
    padding: 0;
}

#product-information-description li:before {
    content: "\00BB \0020";
}

#product-information-description p, #product-information-description li {
    line-height: 25px;
}

#product-grid {
    padding: 100px 0;
}

#product-grid p {
    text-align: center;
}

.product-grid-image {
    position: relative;
    width: 65%;
    margin: 0 auto;
}

#product-howto {
    position: relative;
}

#product-wheretobuy-retailers {
    position: relative;
    margin-bottom: 25px;
}

#product-wheretobuy-retailers img {
    border: 1px solid #efefef;
}

#product-uses {
    position: relative;
    margin-top: 65px;
}

#product-uses li {
    width: 35%;
    float: left;
    margin-bottom: 0.5em;
}

.ps-caption {
    display: none;
}

.hm-variation-grid {
    margin: 45px 0;

    .hm-variation-outer {
        display: table;
        width: 100%;

        .hm-variation-inner:first-child {
            border-top: 1px solid #ccc;
        }

        .hm-variation-inner {
            display: inline-block;
            width: 100%;
            border-bottom: 1px solid #ccc;
            position: relative;
            float: left;

            .hm-variation-image-wrapper {
                height: 65%;
                width: 100%;
                left: 0;
                display: block;
                top: 0;
                margin-top: 25px;

                img.img-responsive {
                    max-height: 200px;
                    position: relative;
                    margin: 0 auto;
                }
            }

            .hm-variation-title {
                text-align: center;
                font-weight: 500;
                margin-top: 25px;
            }

            .hm-variation-description {
                padding: 10px 30px;
                text-align: center;
                box-sizing: border-box;
            }

            .hm-variation-button-wrapper {
                width: 100%;
                z-index: 2;
                margin-bottom: 25px;

                #product-buyNow {
                    margin-top: 0px;
                }
            }
        }
    }
}

#product-where-to-buy {
    padding-top: 50px;

    h2 {
        font-weight: bold;
        margin-bottom: 40px;
        
        .product-name {
            font-weight: 400;
        }
    }
    h3 {
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin-bottom: 20px;
    }

    &.collapseable {
        max-height: 850px;
        padding-top: 0px;
        transition: 0.5s ease max-height;
        overflow: hidden;

        &.loading-online-results {
            .wrapper-label {
                .lds-ring {
                    opacity: 1;
                }
            }
        }

        .wrapper-label {
            transition: 0.5s ease transform, 0.35s ease opacity;
            opacity: 1;
            transform: translateY(0px);

            .lds-ring {
                width: 54px;
                height: 27px;
                transform: scale(0.35);
                transition: 0.35s ease opacity;
                opacity: 0;
            }

            &.local-label {
                transition-delay: 0.15s;
            }
        }

        .online-stores-wrapper,
        .local-stores-wrapper {
            transition: 0.5s ease transform, 0.35s ease opacity;
            opacity: 1;
            transition-delay: 0s;
        }

        &.closed {
            max-height: 0px;
            transition-delay: 0.35s;

            .wrapper-label {
                opacity: 0;
                // transform: translateY(10px);
            }
            .online-stores-wrapper,
            .local-stores-wrapper {
                opacity: 0;
                transition-delay: 0.25s;
            }
        }
    }


    .online-stores-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 50px;

        .online-store-link {
            width: calc(33.33% - 6px);
            margin: 0 3px;
            margin-bottom: 6px;
            height: 75px;
            border: 1px solid #ccc;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.25s ease background-color;
            background-color: white;

            &:hover {
                background-color: #f9f9f9;
            }

            .online-store-logo {
                max-height:70%;
                max-width: 60%;
            }

            #online-store-logo_amazon {
                margin-top: 10px;
            }
        }

        &.animated {
            .online-store-link {
                transition: 0.35s ease opacity, 0.5s ease transform, 0.25s ease background-color;
                opacity: 0;
                transform: translateY(10px);
            }

            &.showing {
                .online-store-link {
                    opacity: 1;
                    transform: translateY(0px);
                }
            }
        }
    }

    .local-stores-wrapper {
        position: relative;
        width: 100%;
        overflow: hidden;

        .form-start-wrapper-outer {
            display: flex;
            margin-bottom: 50px;

            .form-start-wrapper-inner {
                display: flex;
                margin-right: 5px;

                input[type=text],
                input[type=number] {
                    width: 250px;
                    padding: 10px 25px;
                    border-top-left-radius: 20px;
                    border-bottom-left-radius: 20px;
                    border: 1px solid #ccc;
                    outline: none !important;

                    &:focus {
                    }
                }
                input[type=number] {
                    &::-webkit-inner-spin-button, 
                    &::-webkit-outer-spin-button { 
                        -webkit-appearance: none; 
                        margin: 0; 
                    }
                }
                .form-start-submit {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 60px;
                    border-top-right-radius: 20px;
                    border-bottom-right-radius: 20px;
                    background-color: #02a2d4;
                    transition: 0.25s ease background-color, 0.25s ease opacity;
                    cursor: pointer;

                    &.disabled {
                        opacity: 0.5;
                    }
                    &:hover {
                        background-color: #006abc;
                    }

                    .form-start-search-icon {
                        margin-right: 5px;
                    }
                }
            }

            .form-start-location-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 45px;
                border: 1px solid #ccc;
                transition: 0.25s ease background-color;
                background-color: white;
                cursor: pointer;

                &.hidden {
                    display: none;
                }
    
                &:hover {
                    background-color: #f9f9f9;
                }
            }
        }

        .local-stores-results-wrapper {
            position: relative;
            transition: 0.5s ease height;

            .local-stores-controls-wrapper {
                display: flex;
                width: 100%;
                margin-bottom: 25px;

                .local-store-control {
                    position: relative;
                    padding: 15px 25px;
                    width: calc(50% - 10px);
                    border: 1px solid;
                    border-color: #ccc;
                    color: #ccc;
                    text-align: center;
                    transition: 0.35s ease color, 0.35s ease border-color;
                    cursor: pointer;

                    &:first-child {
                        margin-right: 20px;
                    }

                    &.active {
                        border-color: #02a2d4;
                        color: #02a2d4;
                    }
                }
            }

            .local-stores-results-wrapper-inner {
                display: flex;
                width: 200%;
                transition: 0.5s ease transform;
                overflow: hidden;

            }

            &.list-showing {
                .local-stores-results-wrapper-inner {
                    transform: translateX(-50%);
                }
            }

            &.collapsed {
                .map-wrapper {
                    .map-wrapper-inner {
                        transform: translateY(-100%);
                        #map-loader {
                            opacity: 0;
                        }
                        #map-container {
                            transform: translateY(100%);
                        }
                    }  
                }
                .results-wrapper {
                    .results-wrapper-inner {
                        .result-placeholder-wrapper {
                            opacity: 0;
                        }
                    }
                }
            }

            &.map-loaded {
                .map-wrapper {
                    .map-wrapper-inner {
                        #map-container {
                            opacity: 1;
                        }
                    }
                }
            }
            &.results-loaded {
                .results-wrapper {
                    &:before {
                        opacity: 0;
                        pointer-events: none;
                    }
                    #result-loader {
                        opacity: 0;
                        pointer-events: none;
                    }
                    .results-wrapper-inner {
                        .result-wrapper {
                            opacity: 1;
                            transform: translateX(0);
                        }
                    }
                }

            }

            .map-wrapper {
                position: relative;
                width: 100%;
                overflow: hidden;

                .map-wrapper-inner {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    background-color: #efefef;
                    transition: 0.5s ease transform;
                    transform: translateY(0%);

                    #map-container {
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        height: 100%;
                        width: 100%;
                        transition: 0.5s ease transform, 0.35s ease opacity;
                        transform: translateY(0%);
                        opacity: 0;

                        h1 {
                            font-size: 24px;
                        }
                    }
                }
            }
            .results-wrapper {
                position: relative;
                height: 500px;
                width: 100%;
                padding-left: 5px;
                
                &:before {
                    position: absolute;
                    top: 0;
                    left: -100%;
                    height: 100%;
                    width: 100%;
                    background-color: white;
                    opacity: 0.5;
                    transition: 0.35s ease opacity;
                    z-index: 2;
                    content: '';
                }

                #result-loader {
                    position: absolute;
                    top: 50%;
                    left: -50%;
                    transform: translate(-50%, -50%);
                    transition: 0.25s ease opacity;
                    opacity: 1;
                    z-index: 3;
                }

                .results-wrapper-inner {
                    overflow: auto;
                    height: 100%;

                    .result-wrapper {
                        display: flex;
                        padding-right: 25px;
                        margin-bottom: 25px;
                        transition: 0.35s ease transform, 0.35s ease opacity;
                        transform: translateY(10px);
                        opacity: 0;

                        .result-index-wrapper {
                            margin-right: 20px;
                            margin-top: 5px;

                            .result-index {
                                height: 25px;
                                width: 25px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                background-color: #da1a33;
                                color: white;
                                font-weight: bold;
                                border-radius: 50%;
                                text-transform: uppercase;
                            }
                        }
                        .result-info-wrapper {

                            a {
                                &.result-info {
                                    &:hover {
                                        text-decoration: underline;
                                    }
                                }
                            }
                            .result-info {
                                font-size: 12px;
                                margin-right: 5px;

                                &:last-child {
                                    margin-right: 0px;
                                }
                            }
                            .result-name {
                                font-weight: bold;
                            }
                            .result-flex-wrapper {
                                display: flex;
                                align-items: center;
                            }
                            .result-phone {
                                display: block;
                                text-decoration: underline;
                            }
                            .result-hours {
                                color: #ccc;
                            }
                        }
                    }

                    .result-placeholder-wrapper {
                        display: flex;
                        align-items: flex-start;
                        margin-bottom: 25px;
                        transition: 0.35s ease transform, 0.35s ease opacity;
                        transform: translateY(10px);
                        opacity: 1;

                        .result-index-placeholder-wrapper {
                            margin-right: 20px;
                            margin-top: 5px;

                            .result-index-placeholder {
                                height: 25px;
                                width: 25px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                background-color: #da1a33;
                                color: white;
                                font-weight: bold;
                                border-radius: 50%;
                                text-transform: uppercase;
                            }
                        }

                        .result-info-placeholder-wrapper {
                            position: relative;
                            width: 100%;

                            .placeholder {
                                height: 10px;
                                width: 50%;
                                background-color: #efefef;
                                margin-bottom: 10px;

                                &.bold {
                                    background-color: #ccc;
                                }
                                &.long {
                                    width: 75%;
                                }
                                &.short {
                                    width: 35%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .hm-variation-grid {
        .container {
            padding: 0px;
        }
        .hm-variation-outer {
            .hm-variation-inner {
                .hm-variation-title {
                    font-size: 19px;
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .hm-variation-grid {
        .hm-variation-outer {
            border-left: 1px solid #ccc;

            .hm-variation-inner {
                height: 0px;
                border-top: 1px solid #ccc;
                width: 50%;
                padding-bottom: 50%;
                border-right: 1px solid #ccc;

                .hm-variation-image-wrapper {
                    position: absolute;
                    height: 65%;
                    margin-top: 0px;

                    img.img-responsive {
                        max-width: 100%;
                        max-height: 75%;
                        top: 10%;
                    }
                }

                .hm-variation-title {
                    position: absolute;
                    width: 80%;
                    left: 10%;
                    top: 65%;
                    margin-top: 0px;
                }
    
                .hm-variation-description {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0;
                    right: 0;
                    opacity: 0;
                    z-index: 1;
                    color: white;
                    font-weight: 500;
                    background-color: rgba(33, 163, 238, 0.85);
                    transition: 0.5s ease opacity;
    
                    .hm-variation-description-inner {
                        position: absolute;
                        width: 85%;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
    
                .hm-variation-button-wrapper {
                    position: absolute;
                    bottom: 25px;
                    left: 0px;
                    margin-bottom: 0px;
                }

                &.selected {
                    .hm-variation-description {
                        opacity: 1;
                    }
                }
            }

            .hm-variation-inner:hover {
                .hm-variation-description {
                    opacity: 1;
                }
            }
            .hm-variation-inner:nth-child(4n - 1),
            .hm-variation-inner:nth-child(4n) {
                border-top: 0px;
            }
        }
    }

    #product-where-to-buy {
        padding-top: 100px;
    
        .online-stores-wrapper {
            flex-wrap: unset;
            min-height: 75px;

            .online-store-link {
                width: 300px;
                margin-right: 5px;
            }
        }
        .local-stores-wrapper {
            .local-stores-results-wrapper {
                    
                .local-stores-controls-wrapper {
                    display: none;
                }

                .local-stores-results-wrapper-inner {
                    width: 100%;
    
                    .map-wrapper {
                        flex: 1;
                    }
                    .results-wrapper {
                        width: 350px;
                        padding-left: 25px;
                
                        &:before {
                            left: 0%;
                        }

                        #result-loader {
                            left: 50%;
                        }
                        
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 992px) {
    .hm-variation-grid {
        .hm-variation-outer {
            .hm-variation-inner {
                width: 25%;
                padding-bottom: 35%;
            }

            .hm-variation-inner:nth-child(4n - 1),
            .hm-variation-inner:nth-child(4n) {
                border-top: 1px solid #ccc;
            }

            .hm-variation-inner:nth-child(8n - 3),
            .hm-variation-inner:nth-child(8n - 2),
            .hm-variation-inner:nth-child(8n - 1),
            .hm-variation-inner:nth-child(8n) {
                border-top: 0px;
            }
        }
    }
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid #02a2d4;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #02a2d4 transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  